/*=========================================
	++ common.js ++ Ver.1.6.1
	Last update 2020.9.10
==========================================*/
/*
* 2020  9/10 全体を一新
* 2019  5/ 7  *object-fit-images 追加
* 2018 11/20  *ブラウザ判定処理を一新
* 2018 10/25  *jQuery 3系 に対応

*/

'usestrict';

//=====================================
//	Detecting Browser & Device Ver.1.4.2
//============================================

//++++++++++++ モバイル/タブレット/ブラウザ判定処理 ++++++++++++

//ブラウザ/デバイス 種別
var isMobile = false,
	isTablet = false,
	isRetina = false,
	isMobileTablet = false,
	isAndroid = false;

//モバイル　タブレット　判定
//https://w3g.jp/blog/js_browser_sniffing2015
var _ua = (function (u) {
	return {
		Tablet: (u.indexOf("windows") != -1 && u.indexOf("touch") != -1 && u.indexOf("tablet pc") == -1) ||
			u.indexOf("ipad") != -1 ||
			(u.indexOf("android") != -1 && u.indexOf("mobile") == -1) ||
			(u.indexOf("firefox") != -1 && u.indexOf("tablet") != -1) ||
			u.indexOf("kindle") != -1 ||
			u.indexOf("silk") != -1 ||
			u.indexOf("playbook") != -1,
		Mobile: (u.indexOf("windows") != -1 && u.indexOf("phone") != -1) ||
			u.indexOf("iphone") != -1 ||
			u.indexOf("ipod") != -1 ||
			(u.indexOf("android") != -1 && u.indexOf("mobile") != -1) ||
			(u.indexOf("firefox") != -1 && u.indexOf("mobile") != -1) |
			u.indexOf("blackberry") != -1
	};
})(window.navigator.userAgent.toLowerCase());

//モバイル
if (_ua.Mobile) {
	isMobile = true;
	isMobileTablet = true;
}
//タブレット
if (_ua.Tablet) {
	isTablet = true;
	isMobileTablet = true;
}

//Retina Display 判定
if (window.retina || window.devicePixelRatio > 1) {
	isRetina = true;
}

//Android 判定
if (/android[\/\s](\d+\.\d+)/.test(navigator.userAgent)) {
	isAndroid = true;
}


//============================================
//	デバイス　ブラウザ別の処理
//============================================

//++++++++++++ モバイル/タブレット クラス追加 ++++++++++++

$(function () {
	if (isMobile) {
		//HTMLにclass isMobileを追加
		$('html').addClass('isMobile');
		$('html').addClass('isMobileTablet');
	} else if (isTablet) {
		//HTMLにclass isTabletを追加
		$('html').addClass('isTablet');
		$('html').addClass('isMobileTablet');
	}
	//Retina Display isRetina クラス追加
	if (isRetina) {
		$('html').addClass('isRetina');
	}
});




//============================================
//	汎用スクリプト 
//============================================


//++++++ Copyright ++++++
// Copyright <span class="copyYear">&copy;</span>

$(function () {
	let date = new Date();
	let copyYear = date.getFullYear();
	copyYear = '&copy; ' + copyYear;
	$('.copyYear').html(copyYear);
});


$(function () {
	$(".qa_list dd").hide();
	$(".qa_list dl").on("click", function (e) {
		$('dd', this).slideToggle('fast');
		if ($(this).hasClass('open')) {
			$(this).removeClass('open');
		} else {
			$(this).addClass('open');
		}
	});
});








$(function () {
	$('.venobox').venobox({
		infinigall: true,
		numeratio: true,
	});
});




$(function () {
	let mvHeight = $(".mainindex").height();
	let headNav = $(".header");
	//scrollだけだと読み込み時困るのでloadも追加
	$(window).on('load scroll', function () {
		//現在の位置が500px以上かつ、クラスfixedが付与されていない時
		if ($(this).scrollTop() > mvHeight && headNav.hasClass('sticky') == false) {
			//headerの高さ分上に設定
			headNav.css({
				"top": '-100px'
			});
			//クラスfixedを付与
			headNav.addClass('sticky');
			//位置を0に設定し、アニメーションのスピードを指定
			headNav.animate({
				"top": 0
			}, 600);
		}
		//現在の位置が300px以下かつ、クラスfixedが付与されている時にfixedを外す
		else if ($(this).scrollTop() < mvHeight && headNav.hasClass('sticky') == true) {
			headNav.removeClass('sticky');
		}
	});
});



$(function () {
	$(".menubtn").click(function () {
		$(this).toggleClass('active');
		$('body').toggleClass('no-scroll');
		$("#spnav").toggleClass('panelactive');
	});

	$("#spnav a").click(function () {
		$(".menubtn").removeClass('active');
		$('body').removeClass('no-scroll');
		$("#spnav").removeClass('panelactive');
	});
})


$(function () {
	$(".snav_cat a").click(function () {
		$(this).toggleClass('active');
		$('.snav_sub').toggleClass('show');
	});


})



//pagetop
$(function () {
	const topBtn = $('#scroll_top');
	topBtn.hide();
	//スクロールが100に達したらボタン表示
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			//ボタンの表示方法
			topBtn.fadeIn();
		} else {
			//ボタンの非表示方法
			topBtn.fadeOut();
		}
	});

});


new ScrollHint('.js-scrollable', {
	i18n: {
		scrollable: 'スクロールできます'
	}
});



const headerH = $("#header").outerHeight(true); //headerの高さを取得    

//スクロール途中からヘッダーの高さを変化させるための設定を関数でまとめる
function FixedAnime() {
	//ヘッダーの高さを取得
	const scroll = $(window).scrollTop();
	if (scroll >= headerH) { //ヘッダーの高さを超えたら
		$('#header').addClass('HeightMin'); //#headerについているHeightMinというクラス名を付与
	} else {
		$('#header').removeClass('HeightMin'); //HeightMinというクラス名を除去
	}
}

// 画面をスクロールをしたら動かしたい場合の記述
$(window).scroll(function () {
	FixedAnime(); //スクロール途中からヘッダーの高さを変化させる関数を呼ぶ
});


// 動きのきっかけの起点となるアニメーションの名前を定義
function fadeAnime() {
	// ふわっ
	$('.fadeUpTrigger').each(function () { //fadeUpTriggerというクラス名が
		var elemPos = $(this).offset().top - 50; //要素より、50px上の
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();
		if (scroll >= elemPos - windowHeight) {
			$(this).addClass('fadeUp'); // 画面内に入ったらfadeUpというクラス名を追記
		}
	});
}

// 画面をスクロールをしたら動かしたい場合の記述
$(window).scroll(function () {
	fadeAnime(); /* アニメーション用の関数を呼ぶ*/
}); // ここまで画面をスクロールをしたら動かしたい場合の記述



function fadeLeftAnime() {
	// ふわっ
	$('.fadeLeftTrigger').each(function () { //fadeUpTriggerというクラス名が
		var elemPos = $(this).offset().top - 50; //要素より、50px上の
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();
		if (scroll >= elemPos - windowHeight) {
			$(this).addClass('fadeLeft'); // 画面内に入ったらfadeUpというクラス名を追記
		}
	});
}

// 画面をスクロールをしたら動かしたい場合の記述
$(window).scroll(function () {
	fadeLeftAnime(); /* アニメーション用の関数を呼ぶ*/
}); // ここまで画面をスクロールをしたら動かしたい場合の記述


function fadeRightAnime() {
	// ふわっ
	$('.fadeRightTrigger').each(function () { //fadeUpTriggerというクラス名が
		var elemPos = $(this).offset().top - 50; //要素より、50px上の
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();
		if (scroll >= elemPos - windowHeight) {
			$(this).addClass('fadeRight'); // 画面内に入ったらfadeUpというクラス名を追記
		}
	});
}

// 画面をスクロールをしたら動かしたい場合の記述
$(window).scroll(function () {
	fadeRightAnime(); /* アニメーション用の関数を呼ぶ*/
}); // ここまで画面をスクロールをしたら動かしたい場合の記述




function delayScrollAnime() {
	var time = 0.2; //遅延時間を増やす秒数の値
	var value = time;
	$('.delayScroll').each(function () {
		var parent = this; //親要素を取得
		var elemPos = $(this).offset().top; //要素の位置まで来たら
		var scroll = $(window).scrollTop(); //スクロール値を取得
		var windowHeight = $(window).height(); //画面の高さを取得
		var childs = $(this).children(); //子要素を取得

		if (scroll >= elemPos - windowHeight && !$(parent).hasClass("play")) { //指定領域内にスクロールが入ったらまた親要素にクラスplayがなければ
			$(childs).each(function () {

				if (!$(this).hasClass("fadeUpS")) { //アニメーションのクラス名が指定されているかどうかをチェック

					$(parent).addClass("play"); //親要素にクラス名playを追加
					$(this).css("animation-delay", value + "s"); //アニメーション遅延のCSS animation-delayを追加し
					$(this).addClass("fadeUpS"); //アニメーションのクラス名を追加
					value = value + time; //delay時間を増加させる

					//全ての処理を終わったらplayを外す
					var index = $(childs).index(this);
					if ((childs.length - 1) == index) {
						$(parent).removeClass("play");
					}
				}
			})
		} else {
			$(childs).removeClass("fadeUpS"); //アニメーションのクラス名を削除
			value = time; //delay初期値の数値に戻す
		}
	})
}


// $(window).scroll(function () {
// 	delayScrollAnime();
// });


// 画面が読み込まれたらすぐに動かしたい場合の記述
$(window).on('load', function () {
	delayScrollAnime(); /* アニメーション用の関数を呼ぶ*/
}); // ここまで画面が読み込まれたらすぐに動かしたい場合の記述



const scrollThreshold = 400;

function FixedSubAnime() {
	const scroll = $(window).scrollTop();
	const $subNavi = $('.pages_sub_navi');

	if (scroll >= scrollThreshold) {
		$subNavi.addClass('UpMove');
		$subNavi.removeClass('DownMove');
	} else {
		$subNavi.addClass('DownMove');
		$subNavi.removeClass('UpMove');
	}
}

$(window).scroll(function () {
	FixedSubAnime();
});



$(function () {
	var topBtn = $('#page_scroll'),
		scrollHeight = $(document).height(),
		scrollPosition = $(window).height() + $(window).scrollTop();
	topBtn.hide();
	//スクロールが100に達したらボタン表示
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			topBtn.fadeIn();
		} else {
			topBtn.fadeOut();
		}
	});

	$(window).scroll(function () {
		var scrollHeight = $(document).height();
		var scrollPosition = $(window).height() + $(window).scrollTop();
		if ((scrollHeight - scrollPosition) / scrollHeight === 0) {
			topBtn.toggleClass('last_scl');
		}
	});


	//スクロールしてトップ
	topBtn.click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 500);
		return false;
	});
});

$(window).on('load resize', function () {
	if (window.matchMedia('(min-width: 991px)').matches) {
		$('#search_area').each(function () {
			$(this).on('click', function () {
				$('#head_search_form_area').toggleClass('show');
				$("#head_search_form_area").slideToggle();
				return false;
			});
		});
		$('#head_search_close').each(function () {
			$(this).on('click', function () {
				$('#head_search_form_area').removeClass('show');
				$("#head_search_form_area").slideUp();
				return false;
			});
		});
	};
});



$(function () {

	$("#navigation a").on('click', function () {
		$("#navigation").slideUp();

	});

});

//メガーメニュー

$(window).on('load resize', function () {
	var windowSize = $(window).width();
	if (windowSize > 991) {
		$('.mega__triger').each(function () {
			$(this).hover(function () {
				$(this).toggleClass('mega__active');
				$('header').toggleClass('mega__show');
				$(this).children('.mega__navi').slideToggle();
				return false;
			});
		});
	}
});


//animation
$(function () {
	$(window).scroll(function () {
		scroll_effect();
	});
});

function scroll_effect() {
	var tt = $(window).scrollTop();
	var hh = $(window).height();
	$('.anime').each(function () {
		var yy = $(this).offset().top + 200;
		if (tt > yy - hh) {
			$(this).addClass('action');
		}
	});
	$(".anime.action .add").each(function (i) {
		var target = this;
		setTimeout(function () {
			$(target).addClass("fade");
		}, 200 * i);

	});

}




//任意のタブにURLからリンクするための設定
function GethashID(hashIDName) {
	if (hashIDName) {
		//タブ設定
		$('.wp_tab li').find('a').each(function () { //タブ内のaタグ全てを取得
			var idName = $(this).attr('href'); //タブ内のaタグのリンク名（例）#lunchの値を取得 
			if (idName == hashIDName) { //リンク元の指定されたURLのハッシュタグ（例）http://example.com/#lunch←この#の値とタブ内のリンク名（例）#lunchが同じかをチェック
				var parentElm = $(this).parent(); //タブ内のaタグの親要素（li）を取得
				$('.wp_tab li').removeClass("active"); //タブ内のliについているactiveクラスを取り除き
				$(parentElm).addClass("active"); //リンク元の指定されたURLのハッシュタグとタブ内のリンク名が同じであれば、liにactiveクラスを追加
				//表示させるエリア設定
				$(".area").removeClass("is-active"); //もともとついているis-activeクラスを取り除き
				$(hashIDName).addClass("is-active"); //表示させたいエリアのタブリンク名をクリックしたら、表示エリアにis-activeクラスを追加 
			}
		});
	}
}

//タブをクリックしたら
$('.wp_tab a').on('click', function () {
	var idName = $(this).attr('href'); //タブ内のリンク名を取得  
	GethashID(idName); //設定したタブの読み込みと
	return false; //aタグを無効にする
});

// 上記の動きをページが読み込まれたらすぐに動かす
$(window).on('load', function () {
	$('.wp_tab li:first-of-type').addClass("active"); //最初のliにactiveクラスを追加
	$('.area:first-of-type').addClass("is-active"); //最初の.areaにis-activeクラスを追加
	var hashName = location.hash; //リンク元の指定されたURLのハッシュタグを取得
	GethashID(hashName); //設定したタブの読み込み
});




//口臭チェック
$(window).on('load resize', function () {
	var check = $('#check_breath'),
		checkHeader = $('header').outerHeight(),
		checkFv = $('#fv_area').outerHeight(),
		checkPosition = checkHeader + checkFv;


	//スクロールが100に達したらボタン表示
	$(window).scroll(function () {
		if ($(this).scrollTop() > checkPosition) {
			check.addClass('check__follow');
		} else {
			check.removeClass('check__follow');

		}
	});
});


//ドロップダウンの設定を関数でまとめる
function mediaQueriesWin() {
	var width = $(window).width();
	if (width <= 768) { //横幅が768px以下の場合
		$(".has-child>a").off('click'); //has-childクラスがついたaタグのonイベントを複数登録を避ける為offにして一旦初期状態へ
		$(".has-child>a").on('click', function () { //has-childクラスがついたaタグをクリックしたら
			var parentElem = $(this).parent(); // aタグから見た親要素の<li>を取得し
			$(parentElem).toggleClass('active'); //矢印方向を変えるためのクラス名を付与して
			$(parentElem).children('ul').stop().slideToggle(500); //liの子要素のスライドを開閉させる※数字が大きくなるほどゆっくり開く
			return false; //リンクの無効化
		});
	} else { //横幅が768px以上の場合
		$(".has-child>a").off('click'); //has-childクラスがついたaタグのonイベントをoff(無効)にし
		$(".has-child").removeClass('active'); //activeクラスを削除
		$('.has-child').children('ul').css("display", ""); //スライドトグルで動作したdisplayも無効化にする
	}
}

// ページがリサイズされたら動かしたい場合の記述
$(window).resize(function () {
	mediaQueriesWin(); /* ドロップダウンの関数を呼ぶ*/
});

// ページが読み込まれたらすぐに動かしたい場合の記述
$(window).on('load', function () {
	mediaQueriesWin(); /* ドロップダウンの関数を呼ぶ*/
});

//プルダウン クリック付　個別
$(function () {
	$('.rv_title button').on('click', function () {
		if ($(this).parent().parent().hasClass('pull')) {
			$(this).parent().parent().removeClass("pull");
			$(this).parent().parent().addClass("up");
			$(this).parent().parent().find(".rv_info").slideUp(500);

			//$(this).parent().parent().siblings().find("p").slideUp(500);
			//$(this).parent().parent().siblings().removeClass("pull");
			//$(this).parent().parent().siblings().addClass("up");

		} else {
			//$(this).parent().parent().removeClass("up");
			$(this).parent().parent().addClass("pull");
			$(this).parent().parent().find(".rv_info").not(":animated").slideDown(500);


		}
	});
});





